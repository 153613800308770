body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-dark {
  background-color: #094585!important;
}

a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}